

a {
	text-decoration: none;
}

.wrapper {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
}

.container {
	width: 90%;
	margin: 20px auto;
	padding-bottom: 80px;
}

.carousel {
	background-color: #cacbd4;
	text-align: center;
	border-radius: 10px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	height: 130px;
	background-color: ;
}

.carousel img {
	background-color: lightgrey;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	display: none;
}

.carousel img.active {
	display: block;
}

.carousel h2 {
	display: flex;
	font-size: 18px;
	margin-left: 10px;
	margin-right: 10px;
	justify-content: space-between;
	margin-top: -30px;
}

.more {
	color: #6868d6;
}

.section {
	padding: 10px;
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: relative;
	min-height: 140px; /* 设置最小高度 */
	height: auto; /* 允许高度自动扩展 */
}

.section h2 {
	margin: 0;
	font-size: 18px;
}

.section .more {
	float: right;
}

.section ul {
	list-style: none;
	padding: 0;
	margin: 10px 0 0 0;
}

.section ul li {
	margin-bottom: 5px;
}

.tabs {
	display: flex;
	justify-content: space-around;
}

.tabs a {
	color: #000;
	padding: 5px 10px;
	border-radius: 5px;
}

.tabs a:hover {
	background-color: #7377ff;
	color: #fff;
}

.footer {
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: #e0e0e0;
	padding: 10px;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: fixed;
	bottom: 0;
	width: 90%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
}

.footer img {
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.footer .menu {
	font-size: 24px;
}

.jinrishici {
	text-align: center;
	padding: 10px;
	color: blue;
}

#section01 {
	background-color: #fff4f4;
	margin-top: -38px;
	margin-left: 10px;
	margin-right: 10px;
}

#section01 h2 {
	position: relative;
	z-index: 1;
	margin: 0 -10px;
}

#section02 {
	background-color: #ff00003d;
}
